import {
  Button,
  Flex,
  FormLabel,
  Heading,
  Input,
  Select,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useCustomToast } from "../toast";
import axios from "../../../helper/axios";
import { BackButtonSiswa } from "./back";

const FieldInput = ({ label, placeholder, defaultValue, name, ...props }) => {
  return (
    <FormLabel w="inherit">
      <FormLabel fontWeight={"normal"} fontSize="14px" m="2px">
        {label}
      </FormLabel>
      <Field
        name={name}
        as={Input}
        fontSize="14px"
        defaultValue={defaultValue}
        {...props}
      />
      <ErrorMessage
        component="div"
        name={name}
        style={{ color: "red", fontSize: "12px" }}
      />
    </FormLabel>
  );
};

const FieldSelect = ({
  label,
  option,
  placeholder,
  setFieldValue,
  values,
  desc,
  name,
  ...props
}) => {
  return (
    <FormLabel w="inherit">
      <FormLabel fontWeight={"normal"} fontSize="14px" m="2px">
        {label}
      </FormLabel>
      <Select
        value={values[name]}
        name={name}
        {...props}
        onChange={(e) => setFieldValue(name, e.target.value)}
        fontSize="14px"
      >
        {option.map((item, index) => {
          return (
            <option key={index} value={item.id}>
              {item[desc]}
            </option>
          );
        })}
      </Select>
      <ErrorMessage
        component="div"
        name={name}
        style={{ color: "red", fontSize: "12px" }}
      />
    </FormLabel>
  );
};

export const BioOrtu = ({
  Status_ayah,
  Nama_ayah,
  NIK_ayah,
  KotaLahir_ayah,
  TglLahir_ayah,
  NoHp_ayah,
  Pekerjaan_ayah,
  Pendapatan_ayah,
  Pendidikan_ayah,
  Status_ibu,
  Nama_ibu,
  NIK_ibu,
  KotaLahir_ibu,
  TglLahir_ibu,
  NoHp_ibu,
  Pekerjaan_ibu,
  Pendapatan_ibu,
  Pendidikan_ibu,
  Nama_wali,
  NIK_wali,
  KotaLahir_wali,
  TglLahir_wali,
  NoHp_wali,
  Pekerjaan_wali,
  Pendapatan_wali,
  Pendidikan_wali,
}) => {
  const token = localStorage.getItem("token");
  const customToast = useCustomToast();
  const { nps } = useParams();
  const [pekerjaan, setPekerjaan] = useState([]);
  const [pendapatan, setPendapatan] = useState([]);
  const [pendidikan, setPendidikan] = useState([]);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    Status_ayah: Yup.string()
      .oneOf(["Hidup", "Meninggal", "Tidak Diketahui"])
      .required("Status ayah is required"),
    Nama_ayah: Yup.string().when("Status_ayah", {
      is: "Hidup",
      then: (schema) => schema.required("Nama ayah is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    NIK_ayah: Yup.string().when("Status_ayah", {
      is: "Hidup",
      then: (schema) =>
        schema
          .required("NIK ayah is required")
          .length(16, "NIK must be exactly 16 characters"),
      otherwise: (schema) => schema.notRequired(),
    }),
    Status_ibu: Yup.string()
      .oneOf(["Hidup", "Meninggal", "Tidak Diketahui"])
      .required("Status ibu is required"),
    Nama_ibu: Yup.string().when("Status_ibu", {
      is: "Hidup",
      then: (schema) => schema.required("Nama ibu is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    NIK_ibu: Yup.string().when("Status_ibu", {
      is: "Hidup",
      then: (schema) =>
        schema
          .required("NIK ibu is required")
          .length(16, "NIK must be exactly 16 characters"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const getData = async () => {
    try {
      const { data } = await axios.get("/options");
      setPekerjaan(data.pekerjaan);
      setPendapatan(data.pendapatan);
      setPendidikan(data.pendidikan);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      await axios.patch(`/students/${nps}/ortu`, value, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      customToast({
        title: "Success",
        description: "Data Updated",
        status: "success",
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      customToast({
        title: "Warning",
        description: `${err.response.data}`,
        status: "warning",
      });
    }
  };

  useEffect(() => {
    getData();
    window.scroll(0, 0);
  }, []);

  return (
    <Formik
      initialValues={{
        Status_ayah: Status_ayah || "Hidup",
        Nama_ayah: Nama_ayah || "",
        NIK_ayah: NIK_ayah || "",
        KotaLahir_ayah: KotaLahir_ayah || "",
        TglLahir_ayah: TglLahir_ayah || "1985-04-01",
        NoHP_ayah: NoHp_ayah || "",
        Pekerjaan_ayah: Pekerjaan_ayah || null,
        Pendapatan_ayah: Pendapatan_ayah || null,
        Pendidikan_ayah: Pendidikan_ayah || null,
        Status_ibu: Status_ibu || "Hidup",
        Nama_ibu: Nama_ibu || "",
        NIK_ibu: NIK_ibu || "",
        KotaLahir_ibu: KotaLahir_ibu || "",
        TglLahir_ibu: TglLahir_ibu || "1985-04-01",
        NoHP_ibu: NoHp_ibu || "",
        Pekerjaan_ibu: Pekerjaan_ibu || null,
        Pendapatan_ibu: Pendapatan_ibu || null,
        Pendidikan_ibu: Pendidikan_ibu || null,
        Nama_wali: Nama_wali || "",
        NIK_wali: NIK_wali || "",
        KotaLahir_wali: KotaLahir_wali || "",
        TglLahir_wali: TglLahir_wali || "1985-04-01",
        NoHP_wali: NoHp_wali || "",
        Pekerjaan_wali: Pekerjaan_wali || null,
        Pendapatan_wali: Pendapatan_wali || null,
        Pendidikan_wali: Pendidikan_wali || null,
      }}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(data, action) => {
        console.log(data);
        handleSubmit(data);
      }}
    >
      {({ values, dirty, setFieldValue }) => {
        return (
          <Flex direction="column" bgColor="white" p="5" borderRadius="xl">
            <Form>
              <Heading fontSize="25px" m="4">
                Ayah Kandung
              </Heading>
              <Flex
                w="100%"
                borderTop="1px solid"
                borderColor="gray.200"
                pt="3"
                direction={{ lg: "row", base: "column" }}
              >
                <FieldSelect
                  name="Status_ayah"
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Status"
                  placeholder="status"
                  desc="Status"
                  option={[{ id: "Hidup", Status: "Hidup" }, { id: "Meninggal", Status: "Meninggal" }, { id: "Tidak Diketahui", Status: "Tidak Diketahui" }]}
                />
              </Flex>
              <Flex
                w="100%"
                borderColor="gray.200"
                direction={{ lg: "row", base: "column" }}
              >
                <FieldInput
                  placeholder="nama Lengkap"
                  defaultValue={values.Nama_ayah}
                  label="Nama Lengkap"
                  name="Nama_ayah"
                />
                <FieldInput
                  placeholder="nik"
                  defaultValue={values.NIK_ayah}
                  label="NIK"
                  name="NIK_ayah"
                />
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput
                  placeholder="tempat lahir"
                  defaultValue={values.KotaLahir_ayah}
                  label="Tempat lahir"
                  name="KotaLahir_ayah"
                />
                <FieldInput
                  placeholder="tanggal lahir"
                  defaultValue={values.TglLahir_ayah}
                  type="date"
                  label="Tanggal lahir"
                  name="TglLahir_ayah"
                />
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput
                  placeholder="no handphone"
                  defaultValue={values.NoHP_ayah}
                  label="No Handphone"
                  name="NoHP_ayah"
                />
                <FieldSelect
                  name="Pendidikan_ayah"
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Pendidikan"
                  placeholder="pendidikan"
                  desc="Pendidikan"
                  option={pendidikan}
                />
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldSelect
                  name="Pekerjaan_ayah"
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Pekerjaan"
                  placeholder="pekerjaan"
                  desc="pekerjaan"
                  option={pekerjaan}
                />
                <FieldSelect
                  name="Pendapatan_ayah"
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Pendapatan"
                  placeholder="pendapatan"
                  desc="pendapatan"
                  option={pendapatan}
                />
              </Flex>
              <Heading fontSize="25px" m="4">
                Ibu Kandung
              </Heading>
              <Flex
                w="100%"
                borderTop="1px solid"
                borderColor="gray.200"
                pt="3"
                direction={{ lg: "row", base: "column" }}
              >
                <FieldSelect
                  name="Status_ibu"
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Status"
                  placeholder="status"
                  desc="Status"
                  option={[{ id: "Hidup", Status: "Hidup" }, { id: "Meninggal", Status: "Meninggal" }, { id: "Tidak Diketahui", Status: "Tidak Diketahui" }]}
                />
              </Flex>
              <Flex
                w="100%"
                borderColor="gray.200"
                direction={{ lg: "row", base: "column" }}
              >
                <FieldInput
                  placeholder="nama Lengkap"
                  defaultValue={values.Nama_ibu}
                  label="Nama Lengkap"
                  name="Nama_ibu"
                />
                <FieldInput
                  placeholder="nik"
                  defaultValue={values.NIK_ibu}
                  label="NIK"
                  name="NIK_ibu"
                />
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput
                  placeholder="tempat lahir"
                  defaultValue={values.KotaLahir_ibu}
                  label="Tempat lahir"
                  name="KotaLahir_ibu"
                />
                <FieldInput
                  placeholder="tanggal lahir"
                  defaultValue={values.TglLahir_ibu}
                  type="date"
                  label="Tanggal lahir"
                  name="TglLahir_ibu"
                />
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput
                  placeholder="no handphone"
                  defaultValue={values.NoHP_ibu}
                  label="No Handphone"
                  name="NoHP_ibu"
                />
                <FieldSelect
                  name="Pendidikan_ibu"
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Pendidikan"
                  placeholder="pendidikan"
                  desc="Pendidikan"
                  option={pendidikan}
                />
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldSelect
                  name="Pekerjaan_ibu"
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Pekerjaan"
                  placeholder="pekerjaan"
                  desc="pekerjaan"
                  option={pekerjaan}
                />
                <FieldSelect
                  name="Pendapatan_ibu"
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Pendapatan"
                  placeholder="pendapatan"
                  desc="pendapatan"
                  option={pendapatan}
                />
              </Flex>
              <Heading fontSize="25px" m="4">
                Wali
              </Heading>
              <Flex
                w="100%"
                borderTop="1px solid"
                borderColor="gray.200"
                pt="3"
                direction={{ lg: "row", base: "column" }}
              >
                <FieldInput
                  placeholder="nama Lengkap"
                  defaultValue={values.Nama_wali}
                  label="Nama Lengkap"
                  name="Nama_wali"
                />
                <FieldInput
                  placeholder="nik"
                  defaultValue={values.NIK_wali}
                  label="NIK"
                  name="NIK_wali"
                />
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput
                  placeholder="tempat lahir"
                  defaultValue={values.KotaLahir_wali}
                  label="Tempat lahir"
                  name="KotaLahir_wali"
                />
                <FieldInput
                  placeholder="tanggal lahir"
                  defaultValue={values.TglLahir_wali}
                  type="date"
                  label="Tanggal lahir"
                  name="TglLahir_wali"
                />
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldInput
                  placeholder="no handphone"
                  defaultValue={values.NoHP_wali}
                  label="No Handphone"
                  name="NoHP_wali"
                />
                <FieldSelect
                  name="Pendidikan_wali"
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Pendidikan"
                  placeholder="pendidikan"
                  desc="Pendidikan"
                  option={pendidikan}
                />
              </Flex>
              <Flex w="100%" direction={{ lg: "row", base: "column" }}>
                <FieldSelect
                  name="Pekerjaan_wali"
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Pekerjaan"
                  placeholder="pekerjaan"
                  desc="pekerjaan"
                  option={pekerjaan}
                />
                <FieldSelect
                  name="Pendapatan_wali"
                  setFieldValue={setFieldValue}
                  values={values}
                  label="Pendapatan"
                  placeholder="pendapatan"
                  desc="pendapatan"
                  option={pendapatan}
                />
              </Flex>
              <Flex justifyContent="space-between" mt="4">
                <BackButtonSiswa />
                <Button
                  isLoading={loading}
                  type="submit"
                  isDisabled={!dirty}
                  colorScheme="blue"
                >
                  Simpan
                </Button>
              </Flex>
            </Form>
          </Flex>
        );
      }}
    </Formik>
  );
};
