import {
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Biodata } from "./biodata";
import { BioOrtu } from "./ortudata";
import { FormAlamat } from "./alamatdata";
import { BerkasSiswa } from "./berkas";
import axios from "../../../helper/axios";
import { BreadcrumbComp } from "../../breadCump";

export const DetailSiswa = () => {
  const token = localStorage.getItem("token");
  const { nps } = useParams();
  const [active, setActive] = useState("Data Siswa");
  const [data, setData] = useState({});
  const [reload, setReload] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const back = searchParams.get("back")
  const getData = async () => {
    try {
      const { data } = await axios.get(`/students/${nps}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      setData(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
    window.scroll(0, 0);
  }, [reload]);

  return (
    <Flex w="90%" gap="1" direction="column">
      <Flex
        direction="column"
        gap="5"
        position="sticky"
        top="10vh"
        zIndex="10"
        bgColor="gray.200"
        pb="4"
      >
        <BreadcrumbComp
          menu={"Siswa"}
          subMenu={[
            {
              desc: "Daftar Siswa",
              link: back == "baru" ? "../daftar-siswa-baru" : "../daftar-siswa"
            },
            {
              desc: "Detail Siswa",
              link: ""
            }
          ]}
        />
        <Flex gap="3" alignItems="center">
          <Heading fontSize={{ lg: "35px", base: "20px" }}>
            Detail Siswa
          </Heading>
          <Heading fontWeight="none" fontSize={{ lg: "25px", base: "16px" }}>
            {data?.siswa_biodata?.Nama}
          </Heading>
        </Flex>
        <Flex
          bgColor="white"
          w="full"
          borderRadius="xl"
          cursor="pointer"
          fontSize={{ lg: "18px", base: "12px" }}
        >
          <Flex
            key={1}
            h="60px"
            justifyContent="center"
            alignItems="center"
            p="2"
            flex={1}
            bgColor={active === "Data Siswa" ? "blue.400" : "transparent"}
            color={active === "Data Siswa" ? "white" : "black"}
            borderRadius="xl"
            onClick={() => setActive("Data Siswa")}
          >
            <Text
              textAlign="center"
              fontSize={{ md: "14px", base: "10px" }}
              fontWeight="bold"
            >
              Data Siswa
            </Text>
          </Flex>
          <Flex
            key={2}
            h="60px"
            justifyContent="center"
            alignItems="center"
            p="2"
            flex={1}
            bgColor={active === "Data Orang Tua" ? "blue.500" : "transparent"}
            color={active === "Data Orang Tua" ? "white" : "black"}
            borderRadius="xl"
            onClick={() => setActive("Data Orang Tua")}
          >
            <Text
              textAlign="center"
              fontSize={{ md: "14px", base: "10px" }}
              fontWeight="bold"
            >
              Data Orang Tua
            </Text>
          </Flex>
          <Flex
            key={3}
            h="60px"
            justifyContent="center"
            alignItems="center"
            p="2"
            flex={1}
            bgColor={active === "Data Alamat" ? "blue.400" : "transparent"}
            color={active === "Data Alamat" ? "white" : "black"}
            borderRadius="xl"
            onClick={() => setActive("Data Alamat")}
          >
            <Text
              textAlign="center"
              fontSize={{ md: "14px", base: "10px" }}
              fontWeight="bold"
            >
              Data Alamat
            </Text>
          </Flex>
          <Flex
            key={3}
            h="60px"
            justifyContent="center"
            alignItems="center"
            p="2"
            flex={1}
            bgColor={active === "Berkas" ? "blue.400" : "transparent"}
            color={active === "Berkas" ? "white" : "black"}
            borderRadius="xl"
            onClick={() => setActive("Berkas")}
          >
            <Text
              textAlign="center"
              fontSize={{ md: "14px", base: "10px" }}
              fontWeight="bold"
            >
              Berkas
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {active === "Data Siswa" ? (
        <Biodata
          img={data?.berkas?.filter((item) => item.jenis === "pas")[0]}
          nama={data?.siswa_biodata?.Nama}
          nisn={data?.NISN}
          nis={data?.NIS}
          nik={data?.NIK}
          KotaLahir={data?.siswa_biodata?.KotaLahir}
          TglLahir={data?.siswa_biodata?.TglLahir}
          jk={data?.JK}
          posisi={data?.siswa_biodata?.Posisi}
          saudara={data?.siswa_biodata?.Saudara}
          kk={data?.NoKK}
          kepalaKeluarga={data?.KepalaKeluarga}
        />
      ) : active === "Data Orang Tua" ? (
        <BioOrtu
          Status_ayah={data?.siswa_ayah?.Status}
          Nama_ayah={data?.siswa_ayah?.Nama}
          NIK_ayah={data?.siswa_ayah?.NIK}
          TglLahir_ayah={data?.siswa_ayah?.TglLahir}
          KotaLahir_ayah={data?.siswa_ayah?.KotaLahir}
          NoHp_ayah={data?.siswa_ayah?.NoHP}
          Pendapatan_ayah={data?.siswa_ayah?.pendapatanId}
          Pendidikan_ayah={data?.siswa_ayah?.pendidikanId}
          Pekerjaan_ayah={data?.siswa_ayah?.pekerjaanId}
          Status_ibu={data?.siswa_ibu?.Status}
          Nama_ibu={data?.siswa_ibu?.Nama}
          NIK_ibu={data?.siswa_ibu?.NIK}
          TglLahir_ibu={data?.siswa_ibu?.TglLahir}
          KotaLahir_ibu={data?.siswa_ibu?.KotaLahir}
          NoHp_ibu={data?.siswa_ibu?.NoHP}
          Pendapatan_ibu={data?.siswa_ibu?.pendapatanId}
          Pendidikan_ibu={data?.siswa_ibu?.pendidikanId}
          Pekerjaan_ibu={data?.siswa_ibu?.pekerjaanId}
          Nama_wali={data?.siswa_wali?.Nama}
          NIK_wali={data?.siswa_wali?.NIK}
          TglLahir_wali={data?.siswa_wali?.TglLahir}
          KotaLahir_wali={data?.siswa_wali?.KotaLahir}
          NoHp_wali={data?.siswa_wali?.NoHP}
          Pendapatan_wali={data?.siswa_wali?.pendapatanId}
          Pendidikan_wali={data?.siswa_wali?.pendidikanId}
          Pekerjaan_wali={data?.siswa_wali?.pekerjaanId}
        />
      ) : active === "Data Alamat" ? (
        <FormAlamat
          Rt={data?.siswa_alamat?.Rt}
          Rw={data?.siswa_alamat?.Rw}
          Alamat={data?.siswa_alamat?.Alamat}
          KodePos={data?.siswa_alamat?.KodePos}
          provinsiId={data?.siswa_alamat?.provinsiId}
          kabupatenId={data?.siswa_alamat?.kabupatenId}
          kecamatanId={data?.siswa_alamat?.kecamatanId}
          desaId={data?.siswa_alamat?.desaId}
        />
      ) : active === "Berkas" ? (
        <BerkasSiswa
          pas_img={data?.berkas?.filter((item) => item.jenis === "pas")[0]}
          kk_img={data?.berkas?.filter((item) => item.jenis === "kk")[0]}
          akta_img={data?.berkas?.filter((item) => item.jenis === "akta")[0]}
          ktp_ayah_img={
            data?.berkas?.filter((item) => item.jenis === "ktp-ayah")[0]
          }
          ktp_ibu_img={
            data?.berkas?.filter((item) => item.jenis === "ktp-ibu")[0]
          }
          ktp_wali_img={
            data?.berkas?.filter((item) => item.jenis === "ktp-wali")[0]
          }
          kesehatan_img={
            data?.berkas?.filter((item) => item.jenis === "kesehatan")[0]
          }
          pernyataan_siswa_img={
            data?.berkas?.filter((item) => item.jenis === "pernyataan-siswa")[0]
          }
          pernyataan_wali_img={
            data?.berkas?.filter((item) => item.jenis === "pernyataan-wali")[0]
          }
          reload={reload}
          setReload={setReload}
        />
      ) : null}
    </Flex>
  );
};
